<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          支付接口
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->
          <b-button variant="primary" @click="create()" class="mx-2">
            <b-icon icon="plus"></b-icon>
            新增
          </b-button>

          <!--按钮组-->
          <b-button variant="success" @click="edit()" class="mx-2">
            <b-icon icon="pencil"></b-icon>
            编辑
          </b-button>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table show-select fixed-header dense height="70vh" v-model="table.selected" :headers="table.headers"
            :items="table.desserts" :single-select="table.singleSelect" :footer-props="table.footer"
            :server-items-length="table.itemsCount" :options.sync="table.pagination" :no-data-text="table.noDataText"
            :loading="table.loading" :loading-text="table.loadingText" item-key="interfaceId">

            <template v-slot:item.status="{ item }">
              <v-switch class="mt-0 pt-0" v-model="item.status" :true-value="1" :false-value="0"
                @change="onSwitchStatus(item)" color="success" hide-details></v-switch>
            </template>

            <template v-slot:item.sort="{ item }">
              <span>
                <v-btn v-if="!item.edit" small plain v-b-tooltip.hover.lefttop title="双击可修改排序"
                  v-on:click="clickSortField(item)">
                  {{ item.sort }}
                </v-btn>
                <b-input class="w-50" v-if="item.edit" size="sm" v-model="item.sort" v-focus @blur="editSort(item)">
                </b-input>
              </span>
            </template>

            <template v-slot:item.updateTime="{ item }">
              {{ item.updateTime | formatDate }}
            </template>

            <!--状态字段   格式处理-->
            <template v-slot:item.action="{ item }">
              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="编辑" v-on:click="edit(item.interfaceId)">
                <v-icon>la la-edit</v-icon>
              </v-btn>
              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="设置限额" v-on:click="changeLimit(item)">
                <v-icon>la-gear</v-icon>
              </v-btn>

              <!-- <v-btn small text icon fab v-b-tooltip.hover.lefttop title="禁用" v-if="item.status == 1"
                v-on:click="changeStatus(item, 0)">
                <v-icon>icon-xl la la-lock</v-icon>
              </v-btn>

              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="启用" v-else-if="item.status == 0"
                v-on:click="changeStatus(item, 1)">
                <v-icon>icon-xl la la-lock-open</v-icon>
              </v-btn> -->
            </template>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group>
                  <b-form-select :options="filter.conditions" v-model="filter.field"></b-form-select>
                </b-form-group>
                <label class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3">:</label>
                <b-form-group class="mx-2">
                  <b-form-input id="input-2" v-model="filter.keyword" placeholder="检索关键词"></b-form-input>
                </b-form-group>

                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select :options="filter.statusOptions" v-model="filter.status"></b-form-select>
                </b-form-group>

                <b-form-group class="mx-2">
                  <b-button variant="success" v-on:click="getData()">
                    <v-icon small> flaticon-search</v-icon>
                    查询
                  </b-button>
                </b-form-group>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>


    <!--模态框-设置限额-->
    <b-modal ref="limit-modal" static="true" centered title="设置限额">
      <b-container fluid>
        <b-form id="limitForm" method="POST" class="form" novalidate="novalidate">
          <b-row>
            <b-col cols="3" class="text-right">
              <label class="form-group-label">通道名称 :</label>
            </b-col>
            <b-col cols="8">
              <b-form-group>
                <b-form-input type="text" name="channelName" readonly v-model="limitForm.channelName"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3" class="text-right">
              <label class="form-group-label">金额 :</label>
            </b-col>
            <b-col cols="8">
              <b-form-group>
                <b-form-input type="number" name="quotaAmount" v-model="limitForm.quotaAmount"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>

      <template v-slot:modal-footer>
        <b-button v-on:click="$refs['limit-modal'].hide()">取消</b-button>
        <b-button v-on:click="submitChnageLimit()" variant="primary">提交</b-button>
      </template>
    </b-modal>
    <!--模态框-更改订单状态-->

  </div>
</template>

<script>
import Vue from "vue";

import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "List",
  components: {
    KTCard,
  },
  props: {
    title: String,
  },
  data() {
    return {
      channels: [],
      limitForm: {},
      filter: {
        conditions: [{
          value: null,
          text: "检索条件"
        },
        {
          value: "channelAlias",
          text: "通道别名"
        },
        {
          value: "loginAccount",
          text: "登录账号"
        },
        {
          value: "contactPhone",
          text: "联系电话"
        },
        {
          value: "email",
          text: "电子邮箱"
        },
        ],
        statusOptions: [{
          value: null,
          text: "状态"
        },
        {
          value: 0,
          text: "禁用"
        },
        {
          value: 2,
          text: "待审核"
        },
        {
          value: 1,
          text: "正常"
        },
        ],
        field: null,
        keyword: "",
        status: null,
      },

      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 10,
        headers: [{
          text: "通道名称",
          value: "channelName"
        },
        {
          text: "通道别名",
          value: "channelAlias"
        },
        {
          text: "费率",
          value: "feeRate"
        },
        {
          text: "最小订单金额",
          value: "minAmount"
        },
        {
          text: "最大订单金额",
          value: "maxAmount"
        },
        {
          text: "代付限额",
          value: "quotaAmount"
        },
        {
          text: "接口Bean",
          value: "beanName"
        },
        {
          text: "支付接口",
          value: "apiUrl"
        },
        {
          text: "优先顺序",
          value: "sort"
        },
        {
          text: "状态",
          value: "status"
        },
        {
          text: "更新时间",
          value: "updateTime"
        },
        {
          text: "操作",
          value: "action"
        },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {
          itemsPerPage: 20,
        },
        desserts: [],
      },
    };
  },
  mounted() {
    let limitForm = KTUtil.getById("limitForm");
    this.fv1 = formValidation(limitForm, {
      fields: {
        quotaAmount: {
          validators: {
            notEmpty: {
              message: "请输入金额!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });

  },
  created() {
    this.getChannels();
  },

  methods: {
    getChannels() {
      this.channels = [];
      let that = this;
      let params = {
        noPaging: true
      };

      ApiService.query("/api/channel/manage/query_list", {
        params: params,
      }).then(({
        data
      }) => {
        that.channels = data.result.data;
      });
    },
    create() {
      this.$router.push({
        path: "/channel/interface/edit",
      });
    },

    edit(id = undefined) {
      id = id == undefined ? this.getSelectedIds() : [id];
      if (id.length != 1) {
        Swal.fire({
          title: "",
          text: "请选择要编辑的数据行,一次仅且只能选择一条!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      this.$router.push({
        path: "/channel/interface/edit",
        query: {
          interfaceId: id[0],
        },
      });
    },
    clickSortField(item) {
      this.table.editIndex = this.table.desserts.indexOf(item);
      item.edit = true;
      Vue.set(this.table.desserts, this.table.editIndex, item);
    },
    editSort(item) {
      item.edit = false;
      Vue.set(this.table.desserts, this.table.editIndex, item);
      let that = this;
      ApiService.post("/api/channel/interface/manage/update", {
        interfaceId: item.interfaceId,
        sort: item.sort,
      }).then(({
        data
      }) => {
        if (data.success) {
          that.$bvToast.toast(`修改成功`, {
            title: `提示`,
            toaster: "b-toaster-top-center",
            solid: true,
            variant: "success",
            bodyClass: "text-center",
            appendToast: false,
          });
        } else {
          that.$bvToast.toast(`修改失败`, {
            title: `提示`,
            toaster: "b-toaster-top-center",
            solid: true,
            variant: "danger",
            bodyClass: "text-center",
            appendToast: false,
          });
        }
      });
    },
    onSwitchStatus(item) {
      let that = this;
      ApiService.post("/api/channel/interface/manage/update", {
        interfaceId: item.interfaceId,
        status: item.status,
      }).then(({
        data
      }) => {
        if (data.success) {
          Swal.fire({
            title: "",
            text: "修改成功！",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 1500,
          });
          that.getData();
        } else {
          Swal.fire({
            title: "修改失败！",
            text: data.message,
            icon: "warning",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 3000,
          });
        }
      });
    },
    // changeStatus(item, status) {
    //   if (item == null || item == undefined) {
    //     Swal.fire({
    //       title: "",
    //       text: "请选择要处理的数据行!",
    //       icon: "warning",
    //       confirmButtonClass: "btn btn-success",
    //       heightAuto: false,
    //     });
    //     return;
    //   }
    //   let that = this;
    //   ApiService.post("/api/channel/interface/manage/update", {
    //     interfaceId: item.interfaceId,
    //     status: status,
    //   }).then(({
    //     data
    //   }) => {
    //     if (data.success) {
    //       Swal.fire({
    //         title: "",
    //         text: "提交成功！",
    //         icon: "success",
    //         confirmButtonClass: "btn btn-success",
    //         heightAuto: false,
    //         timer: 1500,
    //       });
    //       that.getData();
    //       that.$refs["audit-modal"].hide();
    //     } else {
    //       Swal.fire({
    //         title: "提交失败！",
    //         text: data.message,
    //         icon: "warning",
    //         confirmButtonClass: "btn btn-success",
    //         heightAuto: false,
    //         timer: 3000,
    //       });
    //     }
    //   });
    // },

    getData() {
      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        status: this.filter.status,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;

      ApiService.query("/api/channel/interface/manage/query_list", {
        params: params,
      }).then(({
        data
      }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
      });
    },

    getSelectedIds() {
      let ids = [];
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.interfaceId);
        });
      }
      return ids;
    },
    changeLimit(item) {
      this.limitForm.interfaceId = item.interfaceId;
      this.$set(this.limitForm, "channelName", item.channelName);
      this.$set(this.limitForm, "quotaAmount", item.quotaAmount);
      this.$refs["limit-modal"].show();
    },

    submitChnageLimit() {
      let that = this;
      this.fv1.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post("/api/channel/interface/manage/set_withdraw_limit", that.limitForm).then(
            ({
              data
            }) => {
              if (data.success) {
                Swal.fire({
                  title: "",
                  text: "提交成功！",
                  icon: "success",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 1500,
                });
                that.getData();
                that.$refs["limit-modal"].hide();
              } else {
                Swal.fire({
                  title: "提交失败！",
                  text: data.message,
                  icon: "warning",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 3000,
                });
              }
            }
          );
        }
      });
    },

  },
  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>